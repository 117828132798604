import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SearchResult from '../SearchResult';
import styles from './Searchbar.module.scss';

const Searchbar = ({ searchUrl = '' }) => {
    const [isActive, setActive] = useState(false);
    const [isFocused, setFocus] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const searchInputField = useRef(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!isFocused) return;

        function handleClick(event) {
            if (
                searchInputField.current &&
                !searchInputField.current.contains(event.target)
            ) {
                if (searchInputField.current.value === '') {
                    setFocus(false);
                }
            }
        }
        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);
    }, [isFocused]);

    const handleOnChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleOnBlur = () => {
        if (inputValue === '') {
            setFocus(false);
        }
    };

    const placeHolder =
        isActive || isFocused ? t('Header.search') + '...' : t('Header.search');

    const searchbarClasses = classNames(styles['Searchbar'], {
        [styles['Searchbar--Focused']]: isFocused,
        [styles['Searchbar--Large']]: i18n.language !== 'sv',
    });

    return (
        <div className={searchbarClasses}>
            <label className={'sr-only'} htmlFor="header-search">
                {t('Header.search')}
            </label>
            <form action={searchUrl} method="get">
                <input
                    className={styles['Searchbar__Input']}
                    type="text"
                    id="header-search"
                    name="s"
                    placeholder={placeHolder}
                    autoComplete="off"
                    value={inputValue}
                    ref={searchInputField}
                    onClick={() => setFocus(true)}
                    onMouseEnter={() => setActive(true)}
                    onMouseLeave={() => setActive(false)}
                    onFocus={() => setFocus(true)}
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                />
                <button
                    className={styles['Searchbar__Submit']}
                    type="submit"
                    tabIndex={isFocused && !!inputValue ? '0' : '-1'}>
                    <span className={'sr-only'}>{t('Header.search')}</span>
                </button>
            </form>
        </div>
    );
};

SearchResult.propTypes = {
    searchUrl: PropTypes.string.isRequired,
    modifiers: PropTypes.array,
};

export default Searchbar;
