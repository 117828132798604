import React, { useCallback, useState, useEffect, useRef } from 'react';

import { useTranslation } from 'next-i18next';
import { setCookie, getCookie } from 'cookies-next';
import { throttle } from '../../utils/helpers';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Icon from '../Icon';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import MenuCard from '../MenuCard';
import MobileNavigation from '../MobileNavigation/MobileNavigation';
import MobileSearchbar from '../MobileSearchbar/MobileSearchbar';
import Searchbar from '../Searchbar';
import styles from './Header.module.scss';

const Header = ({
    buttons = [],
    items = [],
    languages = [],
    tabs = [],
    searchUrl = '',
}) => {
    const { t } = useTranslation();
    const searchInputField = useRef(null);
    const lastScrollTop = useRef(0);

    const [isExpanded, setIsExpanded] = useState(false);
    const [expandedIndex, setExpanded] = useState(-1);
    const [isExpandedSearch, setExpandedSearch] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const [hideHeader, setHideHeader] = useState(false);

    const cookieValue = getCookie('selectedSite');

    useEffect(() => {
        function keyListener(e) {
            const listener = keyListenersMap.get(e.keyCode);
            return listener && listener(e);
        }

        document.addEventListener('keydown', keyListener);

        return () => document.removeEventListener('keydown', keyListener);
    }, []);

    // Set cookie automaticly when visiting sub-path
    useEffect(() => {
        const firstPath = window.location.pathname
            .split('/')
            .filter((x) => !!x)[0];
        if (
            ['privat', 'foretag', 'private', 'company'].indexOf(firstPath) !==
            -1
        ) {
            setTabCookie('selectedSite', firstPath);
            setActiveTab(firstPath);
        }
    }, []);

    const handleScroll = useCallback(() => {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        const scrollSpeed = scrollY - lastScrollTop.current;
        const isScrollingDown = scrollSpeed > 0 ? true : false;

        if (scrollY < 350) {
            setHideHeader(false);
        } else if (scrollSpeed >= 6 && isScrollingDown) {
            setHideHeader(true);
        } else if (scrollSpeed <= -6 && !isScrollingDown) {
            setHideHeader(false);
        }

        lastScrollTop.current = scrollY;
    }, [hideHeader]);

    useEffect(() => {
        const handleScrollThrottled = throttle(handleScroll, 100);
        window.addEventListener('scroll', handleScrollThrottled);

        return () => {
            window.removeEventListener('scroll', handleScrollThrottled);
        };
    }, [handleScroll]);

    const closeMenu = () => {
        setIsExpanded(false);
        setExpanded(null);
    };

    const expandMegaMenu = (index) => {
        setExpanded(expandedIndex === index ? -1 : index);
    };

    const headerClassNames = classNames(styles['Header'], {
        [styles['Header--Hidden']]: hideHeader && expandedIndex < 0,
    });

    const keyListenersMap = new Map([[27, closeMenu]]);

    const setTabCookie = (name, value) => {
        setCookie(name, value, { maxAge: 60 * 60 * 24 * 365 });
    };

    const handleClick = (index, id) => {
        setTabCookie('selectedSite', id);

        if (cookieValue && cookieValue === id) {
            setActiveTab(id);
        } else if (!cookieValue && index === 0) {
            setActiveTab(id);
        }
    };

    const setNoScrolling = () => {
        if (!isExpanded) {
            document.body.classList.add('no-scrolling');
        } else {
            document.body.removeAttribute('class');
        }
    };

    const handleSearchButtonClick = () => {
        if (!!isExpandedSearch) {
            searchInputField.current && searchInputField.current.blur();
        }

        setExpandedSearch(!isExpandedSearch);
        searchInputField.current && searchInputField.current.focus();
    };

    return (
        <FocusTrap active={isExpanded}>
            <header className={headerClassNames}>
                <div className={styles['Header__Top']}>
                    <div className={styles['Header__Inner']}>
                        <div className={styles['Header__Left']}>
                            {tabs.map((tab, index) => (
                                <Tab
                                    key={tab.id}
                                    id={tab.id}
                                    index={index}
                                    isActive={activeTab === tab.id}
                                    handleClick={handleClick}
                                    href={tab.href}
                                    title={tab.title}
                                />
                            ))}
                        </div>
                        <div className={styles['Header__Right']}>
                            {searchUrl && <Searchbar {...{ searchUrl }} />}
                            <LanguageSelect {...{ languages }} />
                        </div>
                    </div>
                </div>

                <div className={styles['Header__Bottom']}>
                    <div className={styles['Header__Inner']}>
                        <a href="/" className={styles['Header__Logo']}>
                            <span className="sr-only">{t('Header.home')}</span>
                        </a>
                        <nav className={styles['Header__Navigation']}>
                            <ul className={styles['Header__NavigationList']}>
                                {items.map((item, index) => (
                                    <NavigationListItem
                                        key={index}
                                        id={'itm' + index}
                                        isExpanded={expandedIndex === index}
                                        onClick={() => expandMegaMenu(index)}
                                        {...item}
                                    />
                                ))}
                            </ul>
                        </nav>

                        <div className={styles['Header__Icons']}>
                            <Search
                                {...{
                                    searchUrl,
                                    t,
                                    isExpandedSearch,
                                    setExpandedSearch,
                                    handleSearchButtonClick,
                                }}
                            />
                            {buttons.map(({ link, icon }, k) => (
                                <a
                                    key={k}
                                    href={link.href}
                                    className={styles['Header__MyPages']}>
                                    <Icon
                                        type={icon}
                                        dimensions={{
                                            width: '23px',
                                            height: '23px',
                                        }}
                                    />
                                    <span
                                        className={
                                            styles['Header__ProfileText']
                                        }>
                                        {link.title}
                                    </span>
                                </a>
                            ))}
                            <Hamburger
                                t={t}
                                onClick={() => {
                                    setIsExpanded(!isExpanded);
                                    setExpandedSearch(false);
                                    setNoScrolling();
                                }}
                                isExpanded={isExpanded}
                            />
                        </div>

                        <div className={styles['Header__ButtonContainer']}>
                            {buttons.map((button, k) => (
                                <Button
                                    key={k}
                                    modifiers={['yellowShadow']}
                                    iconSize={'large'}
                                    {...button}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <MobileNavigation
                    isOpen={isExpanded}
                    activeTab={activeTab}
                    {...{ items, languages, tabs }}
                />
            </header>
        </FocusTrap>
    );
};

Header.propTypes = {
    languages: PropTypes.array,
    tabs: PropTypes.array,
    items: PropTypes.array,
    buttons: PropTypes.array,
};

const Tab = ({
    href = '',
    id = '',
    title = '',
    isActive = false,
    index = null,
    handleClick,
}) => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
    }, []);

    const classes = classNames(styles['Header__Tab'], {
        [styles['Header__Tab--Active']]: isActive && mounted,
    });

    return (
        <a
            className={classes}
            href={href}
            onClick={() => handleClick(index, id)}>
            {title}
        </a>
    );
};

Tab.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string,
    id: PropTypes.string,
    index: PropTypes.number,
};

const NavigationListItem = ({
    active,
    isExpanded = false,
    onClick,
    id = '',
    title = '',
    columns = [],
    cards = [],
}) => {
    const classes = classNames(styles['Header__NavigationItem'], {
        [styles['Header__NavigationItem--Active']]: active,
        [styles['Header__NavigationItem--Open']]: isExpanded,
    });

    return (
        <li className={classes}>
            <button
                className={styles['Header__NavigationLink']}
                onClick={onClick}
                aria-expanded={isExpanded}
                aria-controls={id}>
                {title}
            </button>
            <div id={id} className={styles['Header__Menu']}>
                <ul className={styles['Header__MenuInner']}>
                    {columns.map((child, index) => (
                        <ColumnItem key={index} {...child} />
                    ))}
                    {cards.map((card, index) => (
                        <li key={index} className={styles['Header__ListCard']}>
                            <MenuCard {...card} />
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    );
};

NavigationListItem.propTypes = {
    isExpanded: PropTypes.bool,
    onClick: PropTypes.func,
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    columns: PropTypes.array,
    cards: PropTypes.array,
};

const ColumnItem = ({ link = {}, subLinks = {}, active = false }) => {
    const classes = classNames(styles['Header__ListLink'], {
        [styles['Header__ListLink--Visited']]: active,
    });

    return (
        <li className={styles['Header__ListItem']}>
            <a className={classes} target={link.target} href={link.href}>
                {link.title}
            </a>
            {!!subLinks && (
                <ul className={styles['Header__Submenu']}>
                    {subLinks.map((child, index) => (
                        <SubmenuItem key={index} {...child} />
                    ))}
                </ul>
            )}
        </li>
    );
};

ColumnItem.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.shape({
        href: PropTypes.string,
        target: PropTypes.string,
    }),
};

const SubmenuItem = ({
    active = false,
    target = '',
    href = '',
    title = '',
}) => {
    const classes = classNames(styles['Header__SubmenuLink'], {
        [styles['Header__SubmenuLink--Active']]: active,
    });

    return (
        <li className={styles['Header__SubmenuItem']}>
            <a className={classes} target={target} href={href}>
                {title}
            </a>
        </li>
    );
};

SubmenuItem.propTypes = {
    active: PropTypes.bool,
    target: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
};

const Search = ({
    t,
    searchUrl = '',
    isExpandedSearch = false,
    setExpandedSearch,
    handleSearchButtonClick,
}) => {
    const mobileSearchbar = useRef(null);

    const srButtonText = isExpandedSearch
        ? t('Header.close')
        : t('Header.expand');

    useEffect(() => {
        useDocumentEvent('keydown', closeSearchbar);
        useDocumentEvent('focusin', closeSearchbar);
        useDocumentEvent('mousedown', closeSearchbar);
    }, []);

    const useDocumentEvent = (event, callback) => {
        document.addEventListener(event, callback);
        return () => document.removeEventListener(event, callback);
    };

    const closeSearchbar = (e) => {
        if (
            (mobileSearchbar.current &&
                !mobileSearchbar.current.contains(e.target)) ||
            e.keyCode === 27
        ) {
            setExpandedSearch(false);
        }
    };

    return (
        <div
            className={styles['Header__SearchContainer']}
            ref={mobileSearchbar}>
            <button
                aria-expanded={isExpandedSearch}
                aria-controls={'searchbar'}
                id={'search-button'}
                className={styles['Header__SearchButton']}
                onClick={() => handleSearchButtonClick()}>
                <Icon
                    type={'searchAlternative'}
                    dimensions={{
                        width: '22px',
                        height: '22px',
                    }}
                />
                <span className={styles['Header__SearchText']}>
                    <span className={'sr-only'}>{srButtonText + ' '}</span>
                    {t('Header.search')}
                </span>
            </button>
            <MobileSearchbar
                {...{
                    searchUrl,
                    isExpandedSearch,
                    setExpandedSearch,
                }}
            />
        </div>
    );
};

const Hamburger = ({ isExpanded = false, t, onClick }) => {
    const toggleClasses = classNames(styles['Header__Toggle'], {
        [styles['Header__Toggle--Active']]: isExpanded,
    });

    const toggleBurgerText = isExpanded ? t('Header.close') : t('Header.menu');
    return (
        <button
            className={toggleClasses}
            onClick={onClick}
            aria-expanded={isExpanded}>
            <span className={styles['Header__Hamburger']}>
                <span className={styles['Header__Line']} />
                <span className={styles['Header__Line']} />
                <span className={styles['Header__Line']} />
            </span>
            <span className={styles['Header__ToggleText']}>
                {toggleBurgerText}
            </span>
        </button>
    );
};

Hamburger.propTypes = {
    searchUrl: PropTypes.string,
    isExpanded: PropTypes.bool,
};

export default Header;
