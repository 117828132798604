import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './FilterButton.module.scss';

const FilterButton = ({
    id = '',
    title = '',
    href = '',
    isActive = false,
    onClick,
}) => {
    const hasLink = !!href;

    const buttonClasses = classNames(styles['FilterButton'], {
        [styles['FilterButton--Active']]: isActive,
    });

    const buttonAttributes = {
        id: id,
        className: buttonClasses,
        onClick: onClick,
    };

    return hasLink ? (
        <a {...buttonAttributes} href={href}>
            {title}
        </a>
    ) : (
        <button {...buttonAttributes}>{title}</button>
    );
};

FilterButton.propTypes = {
    title: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
};

export default FilterButton;
