import React from 'react';
import { ucFirst } from '../../utils/caseconverters';
import classNames from 'classnames';

import styles from './Spinner.module.scss';

const Spinner = () => {
    return (
        <div className={styles['Spinner']}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

Spinner.propTypes = {};

export default Spinner;
