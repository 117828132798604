import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';
import MenuCard from '../MenuCard';
import styles from './MobileNavigation.module.scss';

const MobileNavigation = ({
    isOpen = false,
    activeTab = null,
    items = [],
    languages = [],
    tabs = [],
}) => {
    if (!items.length) {
        return null;
    }
    const { t } = useTranslation();

    const classes = classNames(styles['MobileNavigation'], {
        [styles['MobileNavigation--Open']]: isOpen,
    });

    return (
        <nav className={classes} aria-label="mobile navigation">
            <div className={styles['MobileNavigation__Inner']}>
                {tabs.map(({ id = '', href = '', title = '' }) => (
                    <a
                        key={id}
                        className={classNames(
                            styles['MobileNavigation__Toggle'],
                            {
                                [styles['MobileNavigation__Toggle--Active']]:
                                    activeTab === id,
                            }
                        )}
                        href={href}>
                        {title}
                    </a>
                ))}
                <ul className={styles['MobileNavigation__Menu']}>
                    {items.map((item, index) => (
                        <MenuItem key={index} t={t} {...item} />
                    ))}
                </ul>
                <MobileNavigationBottom {...{ languages, t }} />
            </div>
        </nav>
    );
};

MobileNavigation.propTypes = {
    isOpen: PropTypes.bool,
    items: PropTypes.array,
    languages: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        })
    ),
};

const MenuItem = ({ id = '', title = '', columns = [], cards = [] }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const clickHandler = () => setIsExpanded(!isExpanded);

    const classes = classNames(styles['MobileNavigation__MenuItemLabel'], {
        [styles['MobileNavigation__MenuItemLabel--Expanded']]: isExpanded,
    });

    return (
        <li className={styles['MobileNavigation__MenuItem']}>
            <button
                className={classes}
                onClick={clickHandler}
                aria-controls={'mobile' + id}>
                {title}
            </button>
            <Submenu {...{ id, columns, cards, isExpanded }} />
        </li>
    );
};

MenuItem.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    columns: PropTypes.array,
    cards: PropTypes.array,
};

const Submenu = ({ id = '', columns = [], cards = [], isExpanded = false }) => {
    const subMenuClasses = classNames(styles['MobileNavigation__Submenu'], {
        [styles['MobileNavigation__Submenu--Open']]: isExpanded,
    });

    return (
        <div id={'mobile' + id} className={subMenuClasses}>
            <ul className={styles['MobileNavigation__SubmenuList']}>
                {columns.map((child, index) => (
                    <SubmenuListItem key={index} {...child} />
                ))}
            </ul>
            {cards.map((cardItem, index) => (
                <MenuCard key={index} {...cardItem} />
            ))}
        </div>
    );
};

Submenu.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    link: PropTypes.shape({
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    children: PropTypes.array,
};

const SubmenuListItem = ({ link = {}, subLinks = [] }) => {
    return (
        <li className={styles['MobileNavigation__SubmenuListItem']}>
            <a
                className={styles['MobileNavigation__SubmenuListLink']}
                target={link.target}
                href={link.href}>
                {link.title}
            </a>
            {!!subLinks && (
                <ul className={styles['MobileNavigation__List']}>
                    {subLinks.map((child, index) => (
                        <li
                            key={index}
                            className={styles['MobileNavigation__ListItem']}>
                            <a
                                className={styles['MobileNavigation__ListLink']}
                                target={child.target}
                                href={child.href}>
                                {child.title}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

SubmenuListItem.propTypes = {
    link: PropTypes.shape({
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    subLinks: PropTypes.array,
};

const MobileNavigationBottom = ({ languages = [], t }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const select = useRef(null);

    useEffect(() => {
        useDocumentEvent('keydown', closeLanguageSelect);
        useDocumentEvent('focusin', closeLanguageSelect);
        useDocumentEvent('mousedown', closeLanguageSelect);
    }, []);

    const useDocumentEvent = (event, callback) => {
        document.addEventListener(event, callback);
        return () => document.removeEventListener(event, callback);
    };

    const closeLanguageSelect = (e) => {
        if (
            (select.current && !select.current.contains(e.target)) ||
            e.keyCode === 27
        ) {
            setIsExpanded(false);
        }
    };

    const classes = classNames(styles['MobileNavigation__LanguageSelect'], {
        [styles['MobileNavigation__LanguageSelect--IsExpanded']]: isExpanded,
    });

    return (
        <div className={styles['MobileNavigation__Bottom']}>
            <div className={classes} ref={select}>
                <button
                    className={styles['MobileNavigation__LanguageButton']}
                    onClick={() => setIsExpanded(!isExpanded)}>
                    {t('Header.language')}
                    <Icon
                        type={isExpanded ? 'chevronThin' : 'globe'}
                        modifiers={
                            isExpanded
                                ? ['languageSelect', 'rotate180']
                                : ['languageSelect']
                        }
                        dimensions={{
                            width: '15px',
                            height: '15px',
                        }}
                    />
                </button>
                <ul className={styles['MobileNavigation__LanguageList']}>
                    {languages.map((language, index) => (
                        <li
                            key={index}
                            className={
                                styles['MobileNavigation__LanguageListItem']
                            }>
                            <a
                                className={
                                    styles['MobileNavigation__LanguageLink']
                                }
                                href={language.href}>
                                {language.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

MobileNavigationBottom.propTypes = {
    languages: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
        })
    ),
};

export default MobileNavigation;
