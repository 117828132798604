import React from 'react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import styles from './Footer.module.scss';

const Footer = ({ columns = {}, contact = {}, social = {} }) => {
    return (
        <footer className={styles['Footer']}>
            <div className={styles['Footer__Container']}>
                <div className={styles['Footer__Inner']}>
                    <nav className={styles['Footer__Nav']} aria-label="Footer">
                        {columns.map((column, index) => (
                            <div key={index} className={styles['Footer__Col']}>
                                <h2 className={styles['Footer__Title']}>
                                    {column.label}
                                </h2>
                                <List links={column.links} />
                            </div>
                        ))}
                    </nav>
                    <div className={styles['Footer__Info']}>
                        <Contact {...contact} />
                        <Social {...social} />
                    </div>
                    <div className={styles['Footer__Logo']} />
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            links: PropTypes.PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    label: PropTypes.string,
                    href: PropTypes.string,
                })
            ),
        })
    ),
    social: PropTypes.shape({
        facebookUrl: PropTypes.string,
        instagramUrl: PropTypes.string,
        linkedinUrl: PropTypes.string,
    }),
    contact: PropTypes.shape({
        mail: PropTypes.string,
        phone: PropTypes.string,
        address: PropTypes.string,
        zipcode: PropTypes.string,
        city: PropTypes.string,
    }),
};

const List = ({ links = [] }) => {
    return (
        <ul className={styles['Footer__List']}>
            {links.map(
                (link, index) =>
                    link.title &&
                    link.href && (
                        <li key={index} className={styles['Footer__Item']}>
                            <a
                                className={styles['Footer__Link']}
                                href={link.href}>
                                {link.title}
                            </a>
                        </li>
                    )
            )}
        </ul>
    );
};

List.propTypes = {
    links: PropTypes.array,
};

const Contact = ({
    mail = '',
    phone = '',
    exchange = '',
    address = '',
    zipcode = '',
    city = '',
}) => {
    const { t } = useTranslation();

    const hasContactInfo =
        !!mail || !!phone || !!address || !!zipcode || !!city;
    return (
        <div>
            {hasContactInfo && (
                <h3 className={styles['Footer__Label']}>
                    {t('Footer.contact')}
                </h3>
            )}
            <div className={styles['Footer__Contact']}>
                <ul className={styles['Footer__ContactList']}>
                    {!!mail && (
                        <li className={styles['Footer__ContactItem']}>
                            <Icon
                                type={'mail'}
                                modifiers={['footer']}
                                dimensions={{ width: '20px', height: '20px' }}
                            />
                            <a
                                className={styles['Footer__ContactInfo']}
                                href={`mailto:${mail}`}>
                                {mail}
                            </a>
                        </li>
                    )}
                    {!!phone && (
                        <li className={styles['Footer__ContactItem']}>
                            <Icon
                                type={'phone'}
                                modifiers={['footer']}
                                dimensions={{ width: '20px', height: '20px' }}
                            />
                            {t('Footer.customer_service')}:{' '}
                            <a
                                className={styles['Footer__ContactInfo']}
                                href={`tel:${phone.replace(/\s/g, '')}`}>
                                {phone}
                            </a>
                        </li>
                    )}
                    {!!exchange && (
                        <li className={styles['Footer__ContactItem']}>
                            <Icon
                                type={'phone'}
                                modifiers={['footer', 'blue']}
                                dimensions={{ width: '20px', height: '20px' }}
                            />
                            {t('Footer.exchange')}:&nbsp;
                            <a
                                className={styles['Footer__ContactInfo']}
                                href={`tel:${exchange.replace(/\s/g, '')}`}>
                                {exchange}
                            </a>
                        </li>
                    )}
                </ul>
                <address>
                    <ul className={styles['Footer__ContactList']}>
                        {!!address && (
                            <li className={styles['Footer__ContactItem']}>
                                {address}
                            </li>
                        )}
                        {!!zipcode && !!city && (
                            <li className={styles['Footer__ContactItem']}>
                                {zipcode} {city}
                            </li>
                        )}
                    </ul>
                </address>
            </div>
        </div>
    );
};

const Social = ({ facebookUrl = '', instagramUrl = '', linkedinUrl = '' }) => {
    const { t } = useTranslation();

    const hasSocial = !!facebookUrl || !!instagramUrl || !!linkedinUrl;
    return (
        <div className={styles['Footer__Social']}>
            {hasSocial && (
                <h3 className={styles['Footer__Label']}>
                    {t('Footer.social')}
                </h3>
            )}
            <div className={styles['Footer__SocialItem']}>
                {!!instagramUrl && (
                    <a
                        href={instagramUrl}
                        className={styles['Footer__IconLink']}
                        rel="noopener noreferrer">
                        <Icon
                            type={'instagram'}
                            modifiers={['yellow']}
                            dimensions={{
                                width: '24px',
                                height: '24px',
                            }}
                        />
                        <span className="sr-only">Instagram</span>
                    </a>
                )}
                {!!linkedinUrl && (
                    <a
                        href={linkedinUrl}
                        className={styles['Footer__IconLink']}
                        rel="noopener noreferrer">
                        <Icon
                            type={'linkedin'}
                            modifiers={['yellow']}
                            dimensions={{
                                width: '24px',
                                height: '24px',
                            }}
                        />
                        <span className="sr-only">LinkedIn</span>
                    </a>
                )}
                {!!facebookUrl && (
                    <a
                        href={facebookUrl}
                        className={styles['Footer__IconLink']}
                        rel="noopener noreferrer">
                        <Icon
                            type={'facebook'}
                            modifiers={['yellow']}
                            dimensions={{
                                width: '24px',
                                height: '24px',
                            }}
                        />
                        <span className="sr-only">Facebook</span>
                    </a>
                )}
            </div>
        </div>
    );
};

export default Footer;
