import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ucFirst } from '../../utils/caseconverters';

import Icon from '../Icon/Icon';
import styles from './ArticleSidebar.module.scss';

const ArticleSidebar = ({ title = '', text = '', theme = '', links = [] }) => {
    const hasContent = title || text || links.length;
    if (!hasContent) {
        return null;
    }
    const classes = classNames(styles['ArticleSidebar'], {
        [styles[`ArticleSidebar--${ucFirst(theme)}`]]: theme === 'gradient',
    });

    return (
        <div className={classes}>
            {title && (
                <h2 className={styles['ArticleSidebar__Title']}>{title}</h2>
            )}

            {text && (
                <div
                    className={styles['ArticleSidebar__Text']}
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}></div>
            )}

            {!!links.length && (
                <ul className={styles['ArticleSidebar__List']}>
                    {links.map((link, index) => (
                        <ListItem
                            key={index}
                            link={link.link}
                            icon={link.icon}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

ArticleSidebar.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    theme: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.shape({
                title: PropTypes.string,
                href: PropTypes.string,
                target: PropTypes.string,
            }),
            icon: PropTypes.string,
        })
    ),
};

const ListItem = ({ link = {}, icon = '' }) => {
    const iconHeight = icon === 'download' ? '14px' : '16px';
    return (
        <li className={classNames(styles['ArticleSidebar__ListItem'])}>
            <a
                className={styles['ArticleSidebar__Link']}
                href={link.href}
                target={link.target}>
                {!!icon && (
                    <span className={styles['ArticleSidebar__Icon']}>
                        <Icon
                            type={icon}
                            dimensions={{
                                width: '14px',
                                height: iconHeight,
                            }}
                        />
                    </span>
                )}
                <span className={styles['ArticleSidebar__LinkText']}>
                    <span className={styles['ArticleSidebar__LinkTextInner']}>
                        {link.title}
                    </span>
                </span>
            </a>
        </li>
    );
};

ListItem.propTypes = {
    link: PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    icon: PropTypes.string,
};

export default ArticleSidebar;
