import React from 'react';

import PropTypes from 'prop-types';
import styles from './MenuCard.module.scss';

const MenuCard = ({ title = '', text = '' }) => {
    return (
        <div className={styles['MenuCard']}>
            <h3 className={styles['MenuCard__Title']}>{title}</h3>
            <p className={styles['MenuCard__Text']}>{text}</p>
        </div>
    );
};

MenuCard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

export default MenuCard;
