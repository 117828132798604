import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import Icon from '../Icon';
import styles from './MobileSearchbar.module.scss';

const MobileSearchbar = ({ searchUrl, isExpandedSearch }) => {
    const { t } = useTranslation();
    const searchInputField = useRef(null);
    const [isActive, setActive] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const classes = classNames(styles['MobileSearchbar'], {
        [styles['MobileSearchbar--Active']]: isExpandedSearch,
    });

    const deleteButtonClasses = classNames(
        styles['MobileSearchbar__DeleteButton'],
        {
            [styles['MobileSearchbar__DeleteButton--Visible']]:
                inputValue !== '' && isActive,
        }
    );

    const submitIndex = isExpandedSearch && !!inputValue ? '0' : '-1';

    const handleClick = (e) => {
        setInputValue('');
        searchInputField.current && searchInputField.current.focus();
    };

    return (
        <div
            className={classes}
            aria-labelledby={'search-button'}
            aria-hidden={!isExpandedSearch}
            id={'searchbar'}>
            <form
                className={styles['MobileSearchbar__Form']}
                action={searchUrl}
                method="get">
                <label className="sr-only" htmlFor="mobile-header-search">
                    {t('Header.search')}
                </label>
                <input
                    className={styles['MobileSearchbar__Input']}
                    type="text"
                    id="mobile-header-search"
                    ref={searchInputField}
                    name="s"
                    placeholder={t('Header.search') + '...'}
                    autoComplete="off"
                    onChange={(event) => setInputValue(event.target.value)}
                    onClick={() => setActive(true)}
                    onFocus={() => setActive(true)}
                    value={inputValue}
                    tabIndex={isExpandedSearch ? '0' : '-1'}
                />
                <input
                    className={styles['MobileSearchbar__Submit']}
                    type="submit"
                    value={t('Header.search')}
                    tabIndex={submitIndex}
                />
                <button
                    className={deleteButtonClasses}
                    onClick={(e) => {
                        handleClick(e);
                    }}
                    type="button"
                    tabIndex={isExpandedSearch ? '0' : '-1'}>
                    <Icon
                        type="close"
                        dimensions={{
                            width: '10px',
                            height: '10px',
                        }}
                    />
                </button>
            </form>
        </div>
    );
};

MobileSearchbar.propTypes = {};

export default MobileSearchbar;
